.App {
  text-align: center;
}

.dark {
  background-color: #18191a;
  color: #f5f6f7;
}

.blue {
  background-color: #010048;
  color: #f6f6f6;
}

.pdr-xxsmall {
  padding-right: 3px;
  color: #ff0000;
}
.pointer {
  cursor: pointer;
}
.preventSelection {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.dpcircle {
  background-size: cover;
  border-radius: 50% 50% 50% 50%;
  width: 180px;
  height: 180px;
  border: #02c002 0.2rem solid;
}
/* ToDo */
.mini-dpcircle {
  background-size: cover;
  border-radius: 50% 50% 50% 50%;
  width: 35px;
  height: 35px;
}
.nav-m {
  margin-top: -13px;
}
.m-left {
  margin-left: 15px;
}
.icon-bar {
  margin-left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
  width: 70px;
}

.icon-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
}
.no-td {
  text-decoration: none;
  width: 33.33%;
}

.bm {
  margin-top: 0px;
  margin-bottom: 50px;
}
.my-img {
  width: 80%;
  max-height: 250px;
}
.small {
  height: 100%;
  width: 100%;
  align-items: center;
}
.c-box-min {
  height: 55px;
  width: 100px;
}
.c-box-xmin {
  height: 20px;
  width: 100px;
}
.full-height {
  height: 50vh;
}
.mr-m {
  color: green;
  margin-top: 0px;
}
.myCol {
  background: #bf3f9f;
}
.mbs {
  justify-content: center;
}
.mtop {
  margin-top: 65px;
}
.mtop2 {
  margin-top: 10px;
  margin-left: -10px;
}
.pding {
  padding: 128px 16px;
}
.c-pad {
  padding: 65px;
}
.outer {
  display: flex;
  justify-content: center;
}

.cardButton {
  position: relative;
  text-align: center;
  transition-duration: 0.7s;
  overflow: hidden;
  cursor: selector;
}

.cardButton:after {
  content: "";
  background: #00ff00;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.cardButton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.circular-mini-div {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
.circular-xl-div {
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
  overflow: hidden;
  border-radius: 50%;
  border: #02c002 0.2rem solid;
}

.circular-mini-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scrollTop {
  position: fixed;
  bottom: 60px;
  right: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 100000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
}

.touch {
  cursor: pointer;
}

/* #top {
  margin: auto;
  width: 100%;
  border: 0px solid white;
  max-width: 500px;
} */

.vl {
  border-left: 4px solid black;
  height: 500px;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: 0;
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  /* #top {
    border: 1px solid red;
  } */
}
